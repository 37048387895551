.root {
	--tieredOptionSetGradient: rgba(255, 255, 255, 0.2), rgba(57, 91, 224, 0.4), rgba(123, 66, 188, 0.4), rgba(224, 56, 117, 0.4), rgba(255, 255, 255, 0.2);
	--backgroundGradient: rgba(237, 59, 124, 0.70) 5.46%, rgba(123, 66, 188, 0.70) 35.21%, rgba(24, 104, 242, 0.70) 70.41%, rgba(0, 129, 150, 0.70) 88.43%;

	position: relative;
	composes: g-grid-container from global;
	composes: g-grid from global;
	gap: var(--hdsplus-spacing-12);
	
	@media (--large) {
		grid-template-rows: min-content 1fr;
	}
}

.text {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;

	@media (--medium-up) {
		grid-column: 1 / span 7;
	}
	@media (--large) {
		grid-column: 1 / span 5;
		grid-row: 1 / 2;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.eyebrow {
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-03);
}

.headline {
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing);
	font-weight: 700;
	margin-bottom: var(--hdsplus-spacing-05);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing);
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-600-font-size);
		line-height: var(--hdsplus-typography-display-expressive-600-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing);
	}
}

.description {
	color: var(--token-color-foreground-primary);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
	font-weight: 400;

	@media (--large) {
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
	}
}

.cta {
	margin-top: var(--hdsplus-spacing-05);
	& .mobile {
		display: flex;
	
		@media (--medium-up) {
			display: none;
		}
	}
	
	& .tablet {
		display: none;
	
		@media (--medium-up) {
			display: flex;
		}
	
		@media (--large) {
			display: none;
		}
	}
	
	& .desktop {
		display: none;
		
		@media (--large) {
			display: flex;
		}
	}
}

.ctaIcon {
	display: flex;
	align-items: center;
	height: 100%;
}

.glow {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	filter: blur(80px);
	transform: translateZ(0);
}

.glowInner {
	position: absolute;
	top: 20%;
	left:0;
	width: 100%;
	height: 50%;
	opacity: 0.3;
	background: linear-gradient(130deg, var(--backgroundGradient));

	@media (--large) {
		top: 0;
		left: unset;
		right: 0;
		width: 55%;
		height: 100%;
		opacity: 0.6;
		animation: 20s clipPath alternate linear infinite;
		clip-path: polygon(2% 19%, 7% 9%, 19% 3%, 47% 28%, 60% 25%, 77% 23%, 91% 28%, 95% 55%, 94% 88%, 90% 95%, 78% 98%, 50% 98%, 30% 92%, 24% 75%, 27% 58%, 30% 51%);
		transform: translateZ(0);
	}
}

@keyframes clipPath {
	0% {
		clip-path: polygon(35.1% 15.9%, 43% 11.3%, 54.6% 9.2%, 72.8% 13.1%, 83.3% 23.4%, 89.9% 37.3%, 94.9% 61.8%, 93.6% 86.9%, 76.8% 96.7%, 57.1% 94.3%, 33.8% 90.5%, 14.9% 62%, 15.9% 36.1%, 6.8% 13%, 9.1% 6.3%, 15.9% 4.9%);
	}
	25% {
		clip-path: polygon(31.5% 11.6%, 43% 11.3%, 63.5% 16.3%, 71.4% 32.6%, 93% 19.8%, 93.5% 36.1%, 70.9% 61.7%, 93.5% 86.8%, 58.1% 80.8%, 57% 94.3%, 32% 74%, 4.8% 82.5%, 12% 65.6%, 13% 25.8%, 9% 6.3%, 15.9% 4.9%);

	}
	50% {
		clip-path: polygon(24.5% 18.4%, 54.8% 28%, 88.8% 6.7%, 93% 13.1%, 75.9% 32.9%, 76.4% 49.2%, 97.9% 47.8%, 78.4% 66.6%, 75% 86.1%, 57% 94.3%, 25.8% 97.2%, 18.1% 57.9%, 2.9% 36.8%, 30.3% 29.4%, 5.4% 25.8%, 4.8% 6.7%);
	}
	75% {
		clip-path: polygon(54.6% 9.1%, 69.1% 13%, 72.6% 2.8%, 94.4% 36.2%, 90% 43%, 76.4% 49.2%, 94.6% 74.9%, 84.1% 94.8%, 50.3% 73.1%, 40.1% 97.4%, 30.6% 90.5%, 5.9% 72.1%, 25.1% 69.6%, 12.3% 45.3%, 5.4% 25.8%, 41.9% 34.7%);
	}
	100% {
		clip-path: polygon(25.9% 4.7%, 48.9% 4.5%, 83.1% 5%, 96.5% 8.6%, 78.4% 43%, 96.3% 70.2%, 87.8% 95.1%, 70.1% 61.6%, 63.3% 72.6%, 23.3% 79%, 6.5% 83.8%, 10% 62.3%, 10.1% 47.6%, 38.4% 65.5%, 29.4% 37.2%, 12.1% 12.3%);
	}
}


.featuredOptions {
	position: relative;
	grid-column: 1 / -1;
	composes: g-grid from global;
	margin: 0;
	padding: 0;
	list-style-type: none;
	gap: var(--hdsplus-spacing-08);

	@media (--large) {
		grid-column: 7 / -1;
		grid-row: 1 / 3;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
}

.options {
	grid-column: 1 / -1;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-07);

	@media (--large) {
		grid-column: 1 / span 5;
		grid-row: 2 / 3;
	}
}

.option {
	display: grid;
	grid-template-columns: min-content 1fr;
	column-gap: var(--hdsplus-spacing-07);
	row-gap: var(--hdsplus-spacing-03);
}

.optionIcon {
	color: var(--hdsplus-color-terraform-button-background-color);
	grid-column: 1 / 2;
	grid-row: 1 / -1;
	align-self: center;
}

.optionHeadline {
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-200-letter-spacing);
	font-weight: 700;
	grid-column: 2/3;
}

.optionDescription {
	grid-column: 2/3;
}
