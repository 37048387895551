.link {
	composes: hds-typography-body-100 from global;
	text-decoration: none;
	color: var(--token-color-foreground-faint);

	&:hover {
		color: var(--token-color-foreground-faint);
	}
}

.cookieManager {
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;

	/* Copy of inline link styles */
	border-radius: 2px;

	&:focus,
	&.mock-focus,
	&:focus-visible {
		text-decoration: none;
		outline: 2px solid var(--token-color-focus-action-internal);
		outline-offset: 1px;
	}
}