.featuredOption {
	position: relative;
	height: 100%;
	width: 100%;
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	padding: var(--hdsplus-spacing-07);
	background: rgba(200,200,200, 0.1);
	
	@media (--medium-up) {
		grid-column: auto / span 4;
	}

	@media (--large) {
		padding: var(--hdsplus-spacing-09) var(--hdsplus-spacing-08) var(--hdsplus-spacing-10);
		grid-column: auto / span 1;
		grid-row: auto / span 1;
		
			&:nth-child(2) {
				transform: translateY(calc((var(--offset) / 2) - 50%));
			}
	}
}

.featuredOptionHeadline {
	font-size: var(--hdsplus-typography-display-expressive-600-font-size);
	line-height: var(--hdsplus-typography-display-expressive-600-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing);
	font-weight: 700;
	padding-bottom: var(--hdsplus-spacing-04);

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-800-font-size);
		line-height: var(--hdsplus-typography-display-expressive-800-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-800-letter-spacing);
	}
}

.featuredOptionSubheadline {
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);
	letter-spacing: var(--hdsplus-typography-display-expressive-100-letter-spacing);
	font-weight: 700;
	padding-bottom: var(--hdsplus-spacing-03);


	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
		letter-spacing: var(--hdsplus-typography-display-expressive-200-letter-spacing);
	}
}

.border {
	--heightOrWidth: 2px;
	--offset: 10px;

	position: absolute;
	inset: 0;
	width: 100%;
}

/* LEFT */

.borderLeftOne {
	position: absolute;
	top: var(--offset);
	left: 0;
	width: var(--heightOrWidth);
	height: 100%;
	background: linear-gradient(180deg, #84CAE0 27.89%, #84CAE0 36.24%, #6A59A1 67.42%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderLeftTwo {
	position: absolute;
	top: var(--offset);
	left: calc(1 * var(--heightOrWidth));
	width: var(--heightOrWidth);
	height: 60%;
	background: linear-gradient(179deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);	
}

.borderLeftThree {
	position: absolute;
	top: var(--offset);
	left: calc(3 * var(--heightOrWidth));
	width: var(--heightOrWidth);
	height: 100%;
	background: linear-gradient(180deg, #84CAE0 27.89%, #84CAE0 36.24%, #6A59A1 67.42%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderLeftFour {
	position: absolute;
	top: var(--offset);
	left: calc(4 * var(--heightOrWidth));
	width: var(--heightOrWidth);
	height: 60%;
	background: linear-gradient(179deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);	
}


/* TOP */

.borderTopOne {
	position: absolute;
	top: 0;
	left: 0;
	height: var(--heightOrWidth);
	width: 100%;
	background: linear-gradient(90deg, #84CAE0 27.9%, #6A59A1 54.62%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderTopTwo {
	position: absolute;
	top: calc(var(--heightOrWidth) * 1);
	left: 0;
	height: var(--heightOrWidth);
	width: 30%;
	background: linear-gradient(90deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);	
}

.borderTopThree {
	position: absolute;
	top: calc(var(--heightOrWidth) * 2);
	left: 0;
	height: var(--heightOrWidth);
	width: 35%;
	background: linear-gradient(90deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderTopFour {
	position: absolute;
	top: calc(var(--heightOrWidth) * 4);
	left: 0;
	height: var(--heightOrWidth);
	width: 100%;
	background: linear-gradient(90deg, #84CAE0 27.9%, #6A59A1 54.62%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderTopFive {
	position: absolute;
	top: calc(var(--heightOrWidth) * 5);
	left: 0;
	height: var(--heightOrWidth);
	width: 30%;
	background: linear-gradient(90deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);	
}

.borderTopSix {
	position: absolute;
	top: calc(var(--heightOrWidth) * 6);
	left: 0;
	height: var(--heightOrWidth);
	width: 35%;
	background: linear-gradient(90deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);
}


/* RIGHT */

.borderRightOne {
	position: absolute;
	bottom: var(--offset);
	right: 0;
	width: var(--heightOrWidth);
	height: 100%;
	background: linear-gradient(0deg, #FAF1A3 27.9%, #E68C81 52.95%, #E2777A 57.96%, rgba(226, 119, 122, 0.00) 83.56%);
}

.borderRightTwo {
	position: absolute;
	bottom: var(--offset);
	right: calc(1 * var(--heightOrWidth));
	width: var(--heightOrWidth);
	height: 60%;
	background: linear-gradient(359deg, #BC78AD 27.9%, #6A59A1 59.62%, rgba(106, 89, 161, 0.00) 83.55%);
}

.borderRightThree {
	position: absolute;
	bottom: var(--offset);
	right: calc(3 * var(--heightOrWidth));
	width: var(--heightOrWidth);
	height: 100%;
	background: linear-gradient(0deg, #FAF1A3 27.9%, #E68C81 52.95%, #E2777A 57.96%, rgba(226, 119, 122, 0.00) 83.56%);
}

.borderRightFour {
	position: absolute;
	bottom: var(--offset);
	right: calc(4 * var(--heightOrWidth));
	width: var(--heightOrWidth);
	height: 60%;
	background: linear-gradient(359deg, #BC78AD 27.9%, #6A59A1 59.62%, rgba(106, 89, 161, 0.00) 83.55%);
}

/* BOTTOM */
.borderBottomOne {
	position: absolute;
	bottom: 0;
	right: 0;
	height: var(--heightOrWidth);
	width: 100%;
	background: linear-gradient(270deg, #FAF1A3 27.9%, #E68C81 50.72%, #E2777A 55.73%, rgba(226, 119, 122, 0.00) 83.56%);	
}

.borderBottomTwo {
	position: absolute;
	bottom: calc(var(--heightOrWidth) * 1);
	right: 0;
	height: var(--heightOrWidth);
	width: 30%;
	background: linear-gradient(270deg, #BC78AD 27.89%, #6A59A1 59.62%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderBottomThree {
	position: absolute;
	bottom: calc(var(--heightOrWidth) * 2);
	right: 0;
	height: var(--heightOrWidth);
	width: 35%;
	background: linear-gradient(270deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderBottomFour {
	position: absolute;
	bottom: calc(var(--heightOrWidth) * 4);
	right: 0;
	height: var(--heightOrWidth);
	width: 100%;
	background: linear-gradient(270deg, #FAF1A3 27.9%, #E68C81 50.72%, #E2777A 55.73%, rgba(226, 119, 122, 0.00) 83.56%);
}

.borderBottomFive {
	position: absolute;
	bottom: calc(var(--heightOrWidth) * 5);
	right: 0;
	height: var(--heightOrWidth);
	width: 30%;
	background: linear-gradient(270deg, #BC78AD 27.89%, #6A59A1 59.62%, rgba(106, 89, 161, 0.00) 83.56%);
}

.borderBottomSix {
	position: absolute;
	bottom: calc(var(--heightOrWidth) * 6);
	right: 0;
	height: var(--heightOrWidth);
	width: 35%;
	background: linear-gradient(270deg, #BC78AD 27.9%, #6A59A1 59.63%, rgba(106, 89, 161, 0.00) 83.56%);
}

